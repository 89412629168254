import PropTypes from 'prop-types'
import {graphql, Link} from 'gatsby'
import {For} from 'babel-plugin-jsx-control-statements'
import {groupBy, orderBy, map, toNumber, split, keys} from 'lodash'
import Appshell from '../components/Appshell'
import Book from '../uikit/Book'
import GatsbyImage from '../components/GatsbyImage'
import Background from '../uikit/Background'
import Flex from '../uikit/Flex'
import Text from '../uikit/Text'
import {months} from '../constants/months'


const ArchivePage = ({data}) => {
  const {frontmatter} = data?.markdownRemark
  const books = data?.books?.nodes

  const groupedbooks = groupBy(books, (book) => {
    const splitedbookSlug = split(book.frontmatter.slug, '/')

    const [year, month] = splitedbookSlug.slice(1)

    return `${year}-${month}`
  })

  const orderedYearsAndMonths = orderBy(
    keys(groupedbooks),
    [],
    ['desc']
  )

  const orderedBookGroups = map(orderedYearsAndMonths, (yearAndMonth) => {
    const [year, month] = yearAndMonth.split('-')

    return {
      year: toNumber(year),
      month: toNumber(month),
      books: groupedbooks[yearAndMonth],
    }
  })


  return (
    <Appshell title={frontmatter?.title} seo={frontmatter?.seo}>
      <Background
          desktopBackground={frontmatter?.desktopBackground?.publicURL}
          mobileBackground={frontmatter?.mobileBackground?.publicURL}
      >
        <Flex column>
          <Text component="h1" textStyle="h1Alternate">{frontmatter?.title}</Text>
          <p>{frontmatter?.description}</p>
          <Flex column>
            <For each="orderedBookGroup" of={orderedBookGroups} index="idx">
              <Flex column key={idx}>
                <Text component="h2">
                  {months[orderedBookGroup.month]} {orderedBookGroup.year}
                </Text>
                <For each="book" of={orderedBookGroup?.books}>
                  <Link to={book.frontmatter?.slug} key={book.id}>
                    <Flex crossAxis="center">
                      <Book maxWidth="4.25rem">
                        <GatsbyImage
                            image={book.frontmatter?.image?.childImageSharp?.gatsbyImageData}
                            alt={book.frontmatter?.imageAlt}
                        />
                      </Book>
                      <div>
                        <Text component="h3">
                          {book.frontmatter?.title}
                        </Text>
                        <Text component="span">
                          {book.frontmatter?.tags}
                        </Text>
                      </div>
                    </Flex>
                  </Link>
                </For>
              </Flex>
            </For>
          </Flex>
        </Flex>
      </Background>
    </Appshell>
  )
}

export const data = graphql`
  query Archive($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        desktopBackground {
          publicURL
        }
        mobileBackground {
          publicURL
        }
        seo {
          metaTitle
          metaDescription
          metaImage {
            publicURL
          }
        }
      }
    }
    books: allMarkdownRemark(
      filter: {frontmatter: {template: {eq: "book"}, homepage: {eq: false}}}
    ) {
      nodes {
        id
        frontmatter {
          slug
          title
          tags
          image {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                transformOptions: {fit: COVER}
                placeholder: BLURRED
              )
            }
          }
          imageAlt
        }
      }
    }
  }
`

ArchivePage.propTypes = {
  data: PropTypes.object,
}

export default ArchivePage
