export const months = {
  1: 'Január',
  2: 'Február',
  3: 'Marec',
  4: 'Apríl',
  5: 'Máj',
  6: 'Jún',
  7: 'Júl',
  8: 'August',
  9: 'September',
  10: 'Október',
  11: 'November',
  12: 'December',
}
