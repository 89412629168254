import PropTypes from 'prop-types'
import styled from 'styled-components'


const StyledBook = styled.div`
  margin: 0 auto;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.8);
  max-width: ${({maxWidth}) => maxWidth ? maxWidth : '15rem'};
`

const Book = ({maxWidth, children, ...props}) => {
  return (
    <StyledBook maxWidth={maxWidth} {...props}>
      {children}
    </StyledBook>
  )
}

Book.propTypes = {
  maxWidth: PropTypes.string,
  children: PropTypes.node,
}

export default Book
